<template>
    <li class="sim-navbar__menu-item"
        :class="{
            'lg:!hidden': mobileOnly,
        }"
        @mouseenter="mouseEnterHandler"
    >
        <!--
            Turn the main link into a button if there is no url for it. In this case we want to remove the functionality
            of the small button that expands the submenu - because the main element will serve that purpose.
          -->
        <component :is="isLink ? NuxtLink : 'button'"
                   class="sim-navbar__menu-link"
                   :class="{
                       'router-link-active': isLinkActiveInBreadcrumbs(link.url),
                   }"
                   :to="link.url!"
                   :type="isLink ? undefined : 'button'"
                   v-bind="isLink ? {} : submenuButtonAttrs"
                   :aria-label="isLink ? undefined : link.ariaLabel"
                   v-on="isLink ? {} : { click: withModifiers(toggleSubmenuVisibility, ['prevent']) }"
        >
            <!--  wrap the link label win a span if there is an icon provided  -->
            <span class="flex gap-4" :aria-hidden="!!link.ariaLabel">
                <slot name="leading" />
                {{ link.label }}
                <slot name="trailing" />
            </span>

            <!--  button to expand the submenu  -->
            <component :is="isLink ? 'button' : 'span'"
                       v-if="hasLinks(link)"
                       class="sim-navbar__menu-link-expand-button"
                       :type="isLink ? 'button' : undefined"
                       v-bind="isLink ? submenuButtonAttrs : {}"
                       v-on="isLink ? { click: withModifiers(toggleSubmenuVisibility, ['prevent']) } : {}"
            >
                <IconChevron :up="isSubmenuOpen" size="xs" aria-hidden />
            </component>
        </component>

        <Transition>
            <div v-show="isSubmenuOpen"
                 :id="controlId"
                 ref="submenuWrapperEl"
                 class="sim-navbar__sub-menu"
            >
                <div class="sim-navbar__content sim-navbar__content--sub-menu">

                    <div class="flex w-full flex-col gap-5 lg:hidden">
                        <div class="flex justify-between">
                            <BaseUiButton
                                class="sim-menu-btn !-mx-2 !px-2"
                                align="left"
                                @click="closeSubmenu"
                            >
                                <IconChevron
                                    size="xs"
                                    right
                                    aria-hidden
                                />

                                <span>
                                    {{ t('accessibility.back') }}
                                </span>
                            </BaseUiButton>

                            <BaseUiButton
                                v-if="link?.url"
                                class="sim-menu-btn !-mx-2 !px-2"
                                align="left"
                                :to="link.url"
                            >
                                <span>
                                    {{ t('accessibility.show_all') }}
                                </span>

                                <IconChevron
                                    size="xs"
                                    left
                                    aria-hidden
                                />
                            </BaseUiButton>
                        </div>

                        <span class="sim-text-large sim-font-medium mb-2" aria-hidden="true">
                            {{ link.submenuHeading ?? link.label }}
                        </span>
                    </div>

                    <ul class="sim-navbar__sub-menu-list">
                        <component :is="subLink.url ? NuxtLink : 'button'"
                                   v-for="(subLink, subLinkIndex) in subLinks"
                                   :key="subLinkIndex"
                                   :to="subLink.url ? subLink.url : undefined"
                                   class="sim-navbar__sub-menu-link"
                        >
                            <UiCardWithImage
                                tag="li"
                                :src="subLink.imgSrc"
                                small
                                floating
                                hover
                            >
                                {{ subLink.label }}
                            </UiCardWithImage>
                        </component>
                    </ul>

                </div>
            </div>
        </Transition>

    </li>
</template>

<script lang="ts" setup>
import { withModifiers } from 'vue'
import type { NavigationLink } from './NavbarComponent.vue'
import { NavbarComponentSymbol } from '../../assets/ts/symbols/provide'
import { NuxtLink } from '#components'

const {
    link,
    index,
    mobileOnly,
    isLinkDisabled,
} = defineProps<{
    link: NavigationLink
    index: number
    mobileOnly?: boolean
    isLinkDisabled?: boolean
}>()

const emit = defineEmits<{
    opened: [index: number]
}>()

const injected = inject(NavbarComponentSymbol)

const isLink = computed(() => !!link.url && !isLinkDisabled)

function hasLinks(link: NavigationLink): boolean {
    return !!(link.subLinks && link.subLinks.length > 0)
}

const { page } = useCurrentPage()
function isLinkActiveInBreadcrumbs(url: string | null | undefined): boolean {
    if (!isLink.value) return false
    return page.value?.isLinkInBreadcrumbs(url) ?? false
}

const { t } = useI18n()

const _isSubmenuOpen = ref<boolean>(false)
const isSubmenuOpen = computed<boolean>(() => _isSubmenuOpen.value && hasLinks(link))
const ariaLabel = computed(() => isSubmenuOpen.value ? t('accessibility.close_submenu') : t('accessibility.open_submenu'))
const controlId = `sim-navbar-submenu-${useId()}`

// TODO: This is intentionally temporarily set to watch the internal _isSubmenuOpen value
//       in order to close the submenu of another item when a link with no submenu is hovered over.
//       It is not the best solution and should be refactored. (because we emit the @opened event when, in fact,
//       the submenu is not opened at all because it doesn't exist)
watch(_isSubmenuOpen, (value) => {
    if (value) {
        // emit an event stating that the submenu of the current link was opened
        emit('opened', index)
    }
})

const submenuWrapperEl = useTemplateRef<HTMLDivElement>('submenuWrapperEl')
const { focused } = useFocusWithin(submenuWrapperEl)
watch(focused, (value) => {
    if (value) return
    _isSubmenuOpen.value = false
})

const submenuButtonAttrs = computed(() => {
    return {
        'aria-controls': controlId,
        'aria-label': ariaLabel.value,
        'aria-expanded': isSubmenuOpen.value,
    }
})

function toggleSubmenuVisibility() {
    _isSubmenuOpen.value = !_isSubmenuOpen.value
}

function closeSubmenu() {
    _isSubmenuOpen.value = false
}

const subLinks = computed(() => {
    return link.subLinks ?? []
})

function mouseEnterHandler() {
    // do not show a hover effect for the mobile menu
    if (injected?.isMobileMenuOpen?.value) return

    _isSubmenuOpen.value = true
}

defineExpose({
    closeSubmenu,
    isSubmenuOpen,
})

</script>

<style lang="scss" scoped>
@use "sass:math";

.sim-navbar__menu-item {
    display: flex;
    align-items: center;
    height: 100%;
}

.sim-navbar--mobile-is-open .sim-navbar__menu-item {
    height: initial;
    width: 100%;

    &:last-child {
        padding-bottom: $sim-menu-mobile-bottom-whitespace;
    }
}

.sim-navbar__menu-link {
    display: flex;
    align-items: center;
    gap: 0.125rem;

    padding: 0.25rem 0.5rem;

    @include sim-text-normal;
    @include sim-btn-ghost-hover;

    transition: filter $sim-trans-time-normal $sim-timing, background-color $sim-trans-time-normal $sim-timing;

    &.router-link-active {
        background-color: $sim-c-primary-100;

        &:hover {
            filter: brightness(0.96);
        }
    }

    button {
        border: none;
        background: transparent;
        cursor: pointer;
    }
}

.sim-navbar--mobile-is-open .sim-navbar__menu-link {
    width: 100%;
    justify-content: space-between;
    padding: 0.625em 1rem;
}

.sim-navbar__menu-link-expand-button {
    transform: rotate(-90deg);
    border-radius: math.div($sim-border-radius-xs, 2);

    @include more-than(lg) {
        transform: initial;
    }
}

.sim-navbar__sub-menu {
    position: absolute;
    inset: 0;

    background-color: $sim-c-white;
    z-index: 1;

    border-bottom: 1px solid $sim-c-basic-400;

    @include more-than(lg) {
        top: initial;
        z-index: -1;
        transform: translateY(100%);
    }

    @include less-than(lg) {
        // Also update the transition duration values in the `NavbarComponent.vue` script constants
        &.v-enter-active {
            transition: transform 300ms $sim-navbar-animation-timing-in;
        }

        &.v-leave-active {
            transition: transform 200ms $sim-navbar-animation-timing-out;
        }

        &.v-enter-from,
        &.v-leave-to {
            transform: translateX(100%);
        }
    }

}

.sim-navbar__content {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;

    gap: 3rem;
}

.sim-navbar--mobile-is-open .sim-navbar__content--sub-menu {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0 !important;
    gap: $sim-submenu-mobile-item-spacing;

    overflow-y: auto;
}

.sim-navbar__sub-menu-list {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding-bottom: $sim-menu-mobile-bottom-whitespace;

    gap: $sim-submenu-mobile-item-spacing;

    @include more-than(lg) {
        display: grid;
        grid-template-columns: repeat(3, 15rem);
        //grid-auto-flow: column;
        //grid-template-rows: repeat(4, 1fr);

        width: initial;
        min-width: 50%;

        gap: $sim-submenu-item-spacing;
        padding-bottom: initial;
    }
}

.sim-navbar__sub-menu-link {
    @include sim-text-small;
}

</style>
