<template>
    <li class="sim-popup-card__list-item">
        <component :is="tag"
                   class="sim-popup-card__list-item-btn"
                   :href="href"
                   v-bind="tag === BaseUiButton ? {
                       variant: 'bare',
                       loading: isLoadingAfterClick,
                   } : {}"
                   @click="handleClick"
        >
            <slot />
        </component>
    </li>
</template>

<script lang="ts" setup>
import { BaseUiButton, NuxtLink } from '#components'
import { SymbolUiPopupCard, type UiPopupCardProvide } from '@theme-types/components/UiPopupCard'

const {
    href,
    onClick,
    noAutoClose,
    normalAnchor,
} = defineProps<{
    href?: string
    onClick?: () => void | Promise<void>
    noAutoClose?: boolean
    /**
     * If `true`, the component will render an `<a>` tag instead of a `<NuxtLink>` component.
     */
    normalAnchor?: boolean
}>()

const tag = computed(() => {
    if (href) {
        return normalAnchor ? 'a' : NuxtLink
    }

    return BaseUiButton
})

const injected = inject<UiPopupCardProvide>(SymbolUiPopupCard)

const isLoadingAfterClick = ref<boolean>(false)

async function handleClick() {
    if (onClick) {
        const maybePromise = onClick()

        // await the result of the onClick() function, if it returns a Promise to show a loading button
        if (maybePromise instanceof Promise) {
            isLoadingAfterClick.value = true

            try {
                await maybePromise
            } finally {
                isLoadingAfterClick.value = false
            }
        }
    }

    if (noAutoClose) return
    injected?.closePopup()
}

</script>

<style lang="scss" scoped>

.sim-popup-card__list-item {
    margin-inline: -1rem;

}

.sim-popup-card__list-item-btn {
    display: block; // needed for <NuxtLink />
    box-sizing: border-box;  // needed for <NuxtLink />

    width: 100%;
    text-align: left;

    padding-block: 0.65rem;
    padding-inline: 1rem;

    transition: background-color $sim-trans-time-normal $sim-timing;

    &:hover {
        background-color: $sim-c-basic-200;
        transition-duration: $sim-trans-time-short;
    }

    @include sim-text-small;

    &.router-link-active {
        @include sim-font-bold;
    }
}

</style>
