<template>
    <BaseUiButton
        class="sim-customer-cart-button sim-menu-btn sim-mobile-menu-btn"
        variant="bare"
        size="none"
        :aria-label="$t('accessibility.cart')"
        @click="openDrawer"
    >
        <span class="absolute -translate-y-1/2 translate-x-1/2">
            <Transition>
                <UiCountIndicator
                    v-if="cart?.totalAmount"
                    class="sim-cart-count"
                    color="success"
                    :count="cart.totalAmount"
                />
            </Transition>
        </span>
        <IconShoppingBag aria-hidden />
    </BaseUiButton>

    <!--  -------------------------------------------------------------------------------- -->
    <!--  CART DRAWER  -->
    <LazyCartDrawer
        v-if="wasDrawerMounted"
        v-model="isDrawerOpen"
        :highlighted-cart-item-id="highlightedCartItemId"
        @closed="highlightedCartItemId = null"
    />
</template>

<script lang="ts" setup>

// TODO: update aria label to include items count
const { cart } = useCart()

const { is: isDrawerOpen, was: wasDrawerMounted } = useLazyMount()

const openDrawer = () => {
    isDrawerOpen.value = true
}

const highlightedCartItemId = ref<number | null>(null)

const { useOn } = useEvents()
useOn('cart:item-added', ({ item }) => {
    highlightedCartItemId.value = item.id
    openDrawer()
})

</script>

<style lang="scss" scoped>

.sim-customer-cart-button {
    position: relative;
}

.sim-cart-count {
    &.v-enter-active,
    &.v-leave-active {
        transition: transform $sim-trans-time-normal $sim-timing;
    }

    &.v-enter-from,
    &.v-leave-to {
        transform: scale(0);
    }
}

</style>
