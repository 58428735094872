<template>
    <a href="#main" class="sim-x-skip-to-main-content-link">
        {{ $t('accessibility.skip_to_main_content') }}
    </a>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

.sim-x-skip-to-main-content-link {
    position: absolute;
    left: -9999px;
    z-index: 999;
    padding: 1em;
    background-color: black;
    color: white;
    opacity: 0;
}

.sim-x-skip-to-main-content-link:focus {
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
}

</style>
