<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 66 20"
         focusable="false"
         fill="none"
         :aria-label="$t('accessibility.icons.simplo')"
    >
        <g clip-path="url(#a)">
            <path :fill="color"
                  d="M7.21 6.918c-.814-.365-1.672-.6-2.571-.6-.621 0-1.093.129-1.414.342-.343.214-.492.536-.492.921 0 1.07 1.63 1.37 3.067 2.036 1.135.514 2.122 1.244 2.122 2.72 0 1.2-.514 2.036-1.265 2.592-.922.708-2.229.944-3.302.944-1.479 0-2.359-.236-3.196-.556l.258-1.884c.88.407 1.802.643 2.83.643.644 0 1.288-.13 1.715-.408.385-.235.602-.578.602-1.092 0-1.07-1.63-1.37-3.025-2.055C1.424 9.985.375 9.235.375 7.693c0-.985.366-1.735 1.029-2.271.75-.62 1.821-.943 3.109-.943 1.18 0 2.122.258 2.979.662l-.278 1.777H7.21ZM9.867 2.906V.508h2.4v2.398h-2.4Zm0 12.644V4.863h2.4v10.69h-2.4v-.003ZM28.946 15.55V9.788c0-1.028-.087-1.864-.43-2.42-.365-.643-1.071-.899-1.95-.899-.88 0-1.673.194-2.617.663.213.514.32 1.157.32 1.929v6.489h-2.4V9.788c0-1.028-.087-1.864-.43-2.42-.366-.643-1.052-.899-1.931-.899-.793 0-1.695.13-2.358.258v8.825h-2.4V5.12c1.458-.343 3.28-.643 4.952-.643 1.287 0 2.335.258 3.108.943 1.459-.663 2.637-.943 4.202-.943 1.566 0 2.637.43 3.345 1.305.686.792.986 1.865.986 3.277v6.489h-2.4l.003.003ZM47.912 12.208c0 1.37.537 1.649 1.243 1.649.278 0 .579-.043.792-.13l.408 1.865c-.472.171-1.093.278-1.78.278-1.242 0-3.066-.492-3.066-3.577V.365L47.91 0v12.208h.003ZM58.601 5.551a3.657 3.657 0 0 1-.123-.806 5.728 5.728 0 0 0-7.454 5.461 5.728 5.728 0 0 0 5.73 5.725 5.728 5.728 0 0 0 5.732-5.725c0-.668-.121-1.305-.33-1.9a3.732 3.732 0 0 1-3.555-2.755Zm-1.846 7.994a3.343 3.343 0 0 1-3.342-3.339 3.343 3.343 0 0 1 6.684 0 3.343 3.343 0 0 1-3.342 3.339Z"
            />
            <path fill="#ED135C"
                  d="M62.652 3.1c.494 0 .933.334 1.062.814.076.284.037.581-.11.834a1.09 1.09 0 0 1-.668.514 1.103 1.103 0 0 1-1.35-.775A1.1 1.1 0 0 1 62.65 3.1m0-1.404a2.503 2.503 0 1 0 .652 4.922 2.503 2.503 0 0 0-.652-4.922Z"
            />
            <path :fill="color"
                  d="M37.592 4.456a6.05 6.05 0 0 0-3.868 1.398l-.177.149v13.994h2.088v-4.369a5.97 5.97 0 0 0 1.957.326c3.268 0 5.927-2.58 5.927-5.75s-2.659-5.75-5.927-5.75v.002Zm0 9.474c-.692 0-1.364-.18-1.957-.523V7.005a3.887 3.887 0 0 1 1.957-.522c2.119 0 3.839 1.67 3.839 3.723s-1.723 3.726-3.84 3.726v-.002Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path :fill="color" d="M.156 0h65v20h-65z" />
            </clipPath>
        </defs>
    </svg>
</template>

<script lang="ts" setup>

const {
    variant = 'light',
} = defineProps<{
    variant?: 'light' | 'dark'
}>()

const color = computed(() => variant === 'light' ? '#fff' : '#29004f')

</script>

<style scoped>

</style>
