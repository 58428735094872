<template>
    <div class="sim-customer-shortcuts">

        <NuxtLink
            v-if="loggedIn"
            class="sim-menu-btn !hidden lg:!flex"
            :to="localePath('customer-favorite-products')"
            :aria-label="$t('accessibility.my_favorites')"
        >
            <IconHeart width="24" height="24" aria-hidden />
        </NuxtLink>

        <CustomerCartButton />

    </div>
</template>

<script lang="ts" setup>

const { loggedIn } = useAuth()
const localePath = useLocalePath()

</script>

<style lang="scss" scoped>

.sim-customer-shortcuts {
    display: flex;
    //align-items: center;
    gap: 1rem;
}

</style>
