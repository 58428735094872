<template>
    <button class="sim-menu-btn sim-mobile-menu-btn"
            :class="{
                'sim-menu-btn--open': isOpen && wasClicked,
                'sim-menu-btn--closed': !isOpen && wasClicked,
            }"
            type="button"
            :aria-expanded="isOpen"
            :aria-controls="menuId"
            :aria-label="isOpen ? $t('accessibility.close_mobile_menu') : $t('accessibility.open_mobile_menu')"
            @click="wasClicked = true"
    >
        <span class="sim-mobile-menu-btn__icon">
            <span
                v-for="i in 3"
                :key="i"
                class="sim-mobile-menu-btn__line"
            />
        </span>
    </button>
</template>

<script lang="ts" setup>

const {
    menuId,
    isOpen,
} = defineProps<{
    menuId: string
    isOpen?: boolean
}>()

const wasClicked = ref<boolean>(false)

const ANIMATION_DURATION_OPEN = 300
const animationDurationOpen = computed(() => `${ANIMATION_DURATION_OPEN}ms`)

const ANIMATION_DURATION_CLOSE = 200
const animationDurationClose = computed(() => `${ANIMATION_DURATION_CLOSE}ms`)

</script>

<style lang="scss" scoped>

$gap-between-lines: 4px;
$line-thickness: 2px;
$offset-from-center: $gap-between-lines + $line-thickness;

.sim-mobile-menu-btn__icon {
    $size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $gap-between-lines;

    width: $size;
    height: $size;
}

.sim-mobile-menu-btn__line {
    display: block;
    width: 18px;
    height: $line-thickness;
    border-radius: 100vh;
    background-color: currentColor;
    transition: transform $sim-trans-time-normal $sim-timing, opacity $sim-trans-time-normal $sim-timing;
}

.sim-menu-btn--open .sim-mobile-menu-btn__line {
    &:nth-child(1) {
        animation: top-line v-bind(animationDurationOpen) $sim-timing forwards;
    }

    &:nth-child(2) {
        opacity: 0;
    }

    &:nth-child(3) {
        animation: bottom-line v-bind(animationDurationOpen) $sim-timing forwards;
    }
}

@keyframes top-line {
    0% {
        transform: translateY(0) rotate(0);
    }

    50% {
        transform: translateY($offset-from-center) rotate(0);
    }

    100% {
        transform: translateY($offset-from-center) rotate(-45deg);
    }
}

@keyframes bottom-line {
    0% {
        transform: translateY(0) rotate(0);
    }

    25% {
        transform: translateY(-$offset-from-center) rotate(0);
    }

    100% {
        transform: translateY(-$offset-from-center) rotate(45deg);
    }
}

.sim-menu-btn--closed .sim-mobile-menu-btn__line {
    &:nth-child(1) {
        animation: top-line-exit v-bind(animationDurationClose) $sim-timing forwards;
    }

    &:nth-child(2) {
        opacity: 1;
    }

    &:nth-child(3) {
        animation: bottom-line-exit v-bind(animationDurationClose) $sim-timing forwards;
    }
}

@keyframes top-line-exit {
    0% {
        transform: translateY($offset-from-center) rotate(-45deg);
    }

    50% {
        transform: translateY($offset-from-center) rotate(0);
    }

    100% {
        transform: translateY(0) rotate(0);
    }
}

@keyframes bottom-line-exit {
    0% {
        transform: translateY(-$offset-from-center) rotate(45deg);
    }

    25% {
        transform: translateY(-$offset-from-center) rotate(0);
    }

    100% {
        transform: translateY(0) rotate(0);
    }
}


</style>
