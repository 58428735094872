<template>
    <ul class="sim-popup-card__list">
        <slot />
    </ul>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

.sim-popup-card__list {
    display: flex;
    flex-direction: column;

    gap: 0.25rem;
}

</style>
