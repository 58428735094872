import { ApiReactiveService, type ApiServiceUseFetchOptions } from '@composable-api/service/api.reactive-service'
import { CategoryTreeModel } from '../models/category-tree.model'

class CategoriesTreeApiReactiveService<M extends CategoryTreeModel> extends ApiReactiveService<M> {
    /**
     * @todo Remove later
     * @deprecated Use useGet instead
     */
    useGetForMenu<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this
            .onlyAttrs([
                CategoryTreeModel.ATTR_ID,
                CategoryTreeModel.ATTR_NAME,
                CategoryTreeModel.ATTR_CHILDREN,
            ])
            .embed([
                CategoryTreeModel.EMBED_URLS,
                CategoryTreeModel.EMBED_THUMBNAIL_IMAGE_URL,
            ])
            .useFetch({
                ...options,
                method: 'GET',
                ssrCache: {
                    key: 'categories-tree-menu',
                    ttl: 60 * 30,   // 30 min
                    swr: true,
                },
                excludeFromDataRefresh: true,
            })
    }

    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }
}

export default function useCategoriesTreeApiService() {
    return new CategoriesTreeApiReactiveService({
        endpoint: '/categories?tree',
    }, CategoryTreeModel)
}
