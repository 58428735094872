<template>
    <CoreContainer class="sim-footer__signature" :aria-label="$t('accessibility.copyright')">
        <BaseContainerContent :padding="{ vertical: 'none' }" class="sim-footer-signature__content">
            <CoreCreatedBySimplo />

            <div class="md:justify-end">
                Copyright © {{ year }}. All Rights Reserved.
            </div>
        </BaseContainerContent>
    </CoreContainer>
</template>

<script lang="ts" setup>
const year = useState(() => new Date().getFullYear())

</script>

<style lang="scss" scoped>
.sim-footer-signature__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    padding-top: 1.5rem;
    padding-bottom: 1.25rem;

    @include more-than(md) {
        flex-direction: row;
        justify-content: space-between;
        gap: 2rem;
        padding-block: 4rem;
    }

    @include sim-text-small;
}
</style>
